// Import Font Awesome at the application level
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import React from 'react';
import './App.css';
import './assets/styles/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

// Import shared pages and components
import Home from './sharedPages/Home/Home'; // Import Home for the root route
import Login from './components/Login/Login'; // Import the shared Login component
import Register from './components/Register/Register';

// Import cleaningApp specific components
import Dashboard from './cleaningApp/features/Dashboard/Dashboard';
import CleanHome from './cleaningApp/features/CleanHome/CleanHome';
import Layout from './cleaningApp/components/Layout/Layout';
import PropertiesIndex from './cleaningApp/features/Properties/PropertiesIndex';
import PropertyDetails from './cleaningApp/features/Properties/PropertyDetails';
import PropertyForm from './cleaningApp/features/Properties/PropertyForm';
import Calendar from './cleaningApp/features/Calendar/Calendar';
import FindUser from './cleaningApp/features/FindUser/FindUser';
import Messages from './cleaningApp/features/Messages/Messages';
import JobDetails from './cleaningApp/features/Calendar/JobDetails';
import JobForm from './cleaningApp/features/Calendar/JobForm';
// Import taxApp specific components
import TaxHome from './taxApp/features/TaxHome/TaxHome';
import Test from './taxApp/features/Test/Test';
import TaxMain from './taxApp/features/TaxMain/TaxMain';
import UploadKi from './taxApp/features/UploadKI/UploadKI';
import TaxDetail from './taxApp/features/TaxDetail/TaxDetail';
import UploadEarnings from './taxApp/features/UploadEarnings/UploadEarnings';
import LinkProperties from './taxApp/features/LinkProperties/LinkProperties';

// Import UserProvider from the context folder
import { UserProvider } from './context/UserContext';

// Add all the icons you will use to the library
library.add(faTrash);

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      {/* Shared Routes */}
      <Route path="/" element={<UserProvider><Home /></UserProvider>} />
      <Route path="/login" element={<UserProvider><Login navigatePath="/tax-main" /></UserProvider>} />
      <Route path="/cleaning/login" element={<UserProvider><Login navigatePath="/cleaning/dashboard" /></UserProvider>} />
      <Route path="/register" element={<UserProvider><Register navigatePath="/tax-main" /></UserProvider>} />
      <Route path="/cleaning/register" element={<UserProvider><Register navigatePath="/cleaning/dashboard"/></UserProvider>} />


      {/* TaxApp Routes */}
      <Route path="/tax-home" element={<UserProvider><TaxHome /></UserProvider>} />
      <Route path="/tax-main" element={<UserProvider><TaxMain /></UserProvider>} />
      <Route path="/upload-earnings" element={<UserProvider><UploadEarnings /></UserProvider>} />
      <Route path="/upload-ki" element={<UserProvider><UploadKi /></UserProvider>} />
      <Route path="/link-properties" element={<UserProvider><LinkProperties /></UserProvider>} />
      <Route path="/tax-detail" element={<UserProvider><TaxDetail /></UserProvider>} />
      <Route path="/test" element={<UserProvider><Test /></UserProvider>} />

      {/* CleaningApp Routes */}
      <Route element={<UserProvider><Layout /></UserProvider>}>
        <Route path="/cleaning" element={<CleanHome />} />
        <Route path="/cleaning/dashboard" element={<Dashboard />} />
        <Route path="/cleaning/properties" element={<PropertiesIndex />} />
        <Route path="/cleaning/properties/:id" element={<PropertyDetails />} />
        <Route path="/cleaning/properties/new" element={<PropertyForm />} />
        <Route path="/cleaning/calendar" element={<Calendar />} />
        <Route path="/cleaning/calendar/:jobId" element={<JobDetails />} />
        <Route path="/cleaning/jobs/new" element={<JobForm />} />
        <Route path="/cleaning/find-user" element={<FindUser />} />
        <Route path="/cleaning/messages" element={<Messages />} />

      </Route>
    </React.Fragment>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchJob, deleteJob } from '../../../api/jobsApi';
import { UserContext } from '../../../context/UserContext';
import styles from './JobDetails.module.css';
// Add this import for the bin icon
import { FaTrash } from 'react-icons/fa';

const JobDetails = ({ jobId, onClose, onJobDeleted }) => {
  const [job, setJob] = useState(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJob(jobId).then(setJob).catch(console.error);
  }, [jobId]);

  if (!job) return <div>Loading...</div>;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : 'N/A'; // Add null check
  };

  const handlePropertyClick = () => {
    navigate(`/cleaning/properties/${job.property.id}`);
  };

  const handleDeleteJob = async () => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await deleteJob(jobId);
        onClose(); // Close the modal after successful deletion
        onJobDeleted(jobId); // Notify the parent component that a job was deleted
      } catch (error) {
        console.error('Error deleting job:', error);
        // You might want to show an error message to the user
      }
    }
  };

  return (
    <div className={styles.jobDetailsContainer}>
      <div className={styles.jobHeader}>
        <h1 className={styles.jobTitle} onClick={handlePropertyClick} style={{ cursor: 'pointer' }}>
          {job.property.title}
        </h1>
        {user.current_role === 'manager' && (
          <button onClick={handleDeleteJob} className={styles.deleteButton} title="Delete Job">
            <FaTrash />
          </button>
        )}
      </div>
      
      <div className={styles.section}>
        <h2>Job Details</h2>
        <p><strong>Status:</strong> {job.status}</p>
        <p><strong>Date:</strong> {formatDate(job.date_of_job)}</p>
        <p><strong>Cleaning Time:</strong> {formatTime(job.cleaning_from)} - {formatTime(job.cleaning_until)}</p>
        {job.description && <p><strong>Description:</strong> {job.description}</p>}
      </div>

      {user.current_role === 'manager' ? (
        <>
          <div className={styles.section}>
            <h2>Financial Information</h2>
            <p><strong>Price:</strong> {job.price_cents / 100} {job.price_currency}</p>
          </div>

          {job.selected_cleaner && (
            <div className={styles.section}>
              <h2>Selected Cleaner</h2>
              <p><strong>Name:</strong> {job.selected_cleaner.first_name} {job.selected_cleaner.last_name}</p>
              <p><strong>Email:</strong> {job.selected_cleaner.email}</p>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.section}>
            <h2>Financial Information</h2>
            <p><strong>Revenue:</strong> {job.revenue_cents / 100} {job.revenue_currency}</p>
          </div>

          <div className={styles.section}>
            <h2>Property Information</h2>
            <p><strong>Square Meters:</strong> {job.property.square_meters}</p>
            <p><strong>Number of Rooms:</strong> {job.property.number_rooms}</p>
            <p><strong>Number of Bathrooms:</strong> {job.property.number_bathrooms}</p>
          </div>

          <div className={styles.section}>
            <h2>Property Address</h2>
            <p>{job.property.address}</p>
            {/* Placeholder for future MapBox integration */}
            <div className={styles.mapPlaceholder}>Map will be displayed here</div>
          </div>

          <div className={styles.section}>
            <h2>Manager Information</h2>
            <p><strong>Name:</strong> {job.manager.first_name} {job.manager.last_name}</p>
            <p><strong>Email:</strong> {job.manager.email}</p>
          </div>
        </>
      )}

      <button onClick={onClose} className={styles.closeButton}>Close</button>
    </div>
  );
};

export default JobDetails;

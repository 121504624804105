import React, { useContext, useState, useEffect, useReducer } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logonavbar.png';
import { UserContext } from '../../../context/UserContext';
import styles from './Navbar.module.css';
import MobileNavbar from './MobileNavbar';

export default function Header() {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  console.log("The user in the navbar is", user);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Set initial mobile state
    setIsMobile(window.innerWidth <= 768);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Update mobile state when user changes
    if (!user) {
      setIsMobile(false);
    } else {
      setIsMobile(window.innerWidth <= 768);
    }
  }, [user]);

  const handleLogout = async () => {
    await logout();
    navigate('/cleaning');
  };

  if (isMobile) {
    return <MobileNavbar />;
  }

  return (
    <header className={styles.header}>
      <nav className={`navbar navbar-expand-sm navbar-light ${styles['navbar-lewagon']}`}>
        <div className="container-fluid">
          <a href="/cleaning" className={`navbar-brand ${styles['navbar-brand']}`}>
            <img src={logo} alt="Le Wagon" width="40" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto menu__list r-list">
              {user ? (
                <>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/find-user"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      Find Cleaner
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/calendar"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      Calendar
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/properties"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      My Properties
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="/cleaning/messages"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      Messages
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <NavLink
                      to="cleaning/dashboard"
                      className={({ isActive }) => 
                        `${styles['nav-link']} ${isActive ? styles.active : ''}`
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className={styles['nav-item']}>
                    <button className="btn btn-secondary" onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <li className={styles['nav-item']}>
                  <NavLink 
                    to="/cleaning/login" 
                    className={({ isActive }) => 
                      `nav-link menu__link r-link text-underlined ${styles['nav-link']} ${isActive ? styles.active : ''}`
                    }
                  >
                    Login
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { fetchProperties, fetchProperty } from '../../../api/propertiesApi';
import { createJob } from '../../../api/jobsApi';
import styles from './JobForm.module.css';

export default function JobForm() {
  const [properties, setProperties] = useState([]);
  const [formData, setFormData] = useState({
    property_id: '',
    date_of_job: '',
    revenue: '',
    revenue_currency: '',
    price: '',
    price_currency: '',
    cleaning_from: '',
    cleaning_until: '',
    description: '',
    post_all: false
  });
  const [selectedProperty, setSelectedProperty] = useState(null);

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProperties = async () => {
      try {
        const properties = await fetchProperties();
        setProperties(properties);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };
    loadProperties();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePropertySelect = async (e) => {
    const propertyId = e.target.value;
    try {
      const property = await fetchProperty(propertyId);
      setSelectedProperty(property);
      setFormData(prevState => ({
        ...prevState,
        property_id: propertyId,
        price: property && property.default_job_price_cents != null 
          ? (property.default_job_price_cents / 100).toString() 
          : '',
        price_currency: property?.default_job_price_currency || '',
        cleaning_from: property?.default_cleaning_from || '',
        cleaning_until: property?.default_cleaning_until || '',
        description: property?.description || ''
      }));
    } catch (error) {
      console.error('Error fetching property:', error);
      // Optionally, you can set some error state here to display to the user
      setFormData(prevState => ({
        ...prevState,
        property_id: propertyId,
        price: '',
        price_currency: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Convert price to cents before sending
      const jobData = {
        ...formData,
        price_cents: Math.round(parseFloat(formData.price) * 100),
        user_id: user.id,
        // Set date_of_job to today's date if it's empty
        date_of_job: formData.date_of_job || new Date().toISOString().split('T')[0]
      };
      // Remove the original price field
      delete jobData.price;
      
      const newJob = await createJob(jobData);
      console.log('Job created:', newJob);
      navigate('/cleaning/calendar');
    } catch (error) {
      console.error('Error creating job:', error);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formTitle}>Create New Job</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="property_id" className={styles.formLabel}>Select Property</label>
          <select
            id="property_id"
            name="property_id"
            value={formData.property_id}
            onChange={handlePropertySelect}
            className={styles.formSelect}
          >
            <option value="">Select Property</option>
            {properties.map(property => (
              <option key={property.id} value={property.id}>{property.title}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="date_of_job" className={styles.formLabel}>Date of Job</label>
          <input
            type="date"
            id="date_of_job"
            name="date_of_job"
            value={formData.date_of_job || new Date().toISOString().split('T')[0]}
            onChange={handleInputChange}
            className={styles.formControl}
          />
        </div>

        {(user?.concierge ?? false) && (
          <div className={styles.formGroup}>
            <label htmlFor="revenue" className={styles.formLabel}>Revenue</label>
            <div className={styles.currencyInput}>
              <span className={styles.currencySymbol}>€</span>
              <input
                type="number"
                id="revenue"
                name="revenue"
                value={formData.revenue}
                onChange={handleInputChange}
                className={`${styles.formControl} ${styles.currencyControl}`}
                placeholder="Revenue"
              />
            </div>
          </div>
        )}

        <div className={styles.formGroup}>
          <label htmlFor="price" className={styles.formLabel}>Price (€)</label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            className={styles.formControl}
            placeholder="Job Price"
            min="0.01"
            step="0.01"
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="cleaning_from" className={styles.formLabel}>Cleaning From</label>
          <input
            type="time"
            id="cleaning_from"
            name="cleaning_from"
            value={formData.cleaning_from}
            onChange={handleInputChange}
            className={styles.formControl}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="cleaning_until" className={styles.formLabel}>Cleaning Until</label>
          <input
            type="time"
            id="cleaning_until"
            name="cleaning_until"
            value={formData.cleaning_until}
            onChange={handleInputChange}
            className={styles.formControl}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="description" className={styles.formLabel}>Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className={styles.formControl}
            rows="5"
            placeholder="Description"
          />
        </div>

        <button type="submit" className={styles.submitButton}>
          Create Job
        </button>
      </form>
    </div>
  );
}
import React, { useState, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import styles from './MobileNavbar.module.css';
import { FaCalendarAlt, FaHome, FaEnvelope, FaChartBar, FaBars, FaSearch, FaSignOutAlt } from 'react-icons/fa';

export default function MobileNavbar() {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate('/cleaning');
    setShowMenu(false);
  };

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <div className={styles.mobileNavbarWrapper}>
      <nav className={styles.mobileNavbar}>
        <NavLink to="/cleaning/calendar" className={styles.navItem}>
          <FaCalendarAlt className={styles.icon} />
          <span>Calendar</span>
        </NavLink>
        <NavLink to="/cleaning/properties" className={styles.navItem}>
          <FaHome className={styles.icon} />
          <span>Properties</span>
        </NavLink>
        <NavLink to="/cleaning/messages" className={styles.navItem}>
          <FaEnvelope className={styles.icon} />
          <span>Messages</span>
        </NavLink>
        <NavLink to="/cleaning/dashboard" className={styles.navItem}>
          <FaChartBar className={styles.icon} />
          <span>Dashboard</span>
        </NavLink>
        <button onClick={toggleMenu} className={styles.navItem}>
          <FaBars className={styles.icon} />
          <span>Menu</span>
        </button>
      </nav>
      {showMenu && (
        <div className={styles.menu}>
          <NavLink to="/cleaning/find-user" className={styles.menuItem} onClick={() => setShowMenu(false)}>
            <FaSearch className={styles.icon} />
            <span>Find Cleaner</span>
          </NavLink>
          <button onClick={handleLogout} className={styles.menuItem}>
            <FaSignOutAlt className={styles.icon} />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
